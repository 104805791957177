<template>
  <b-modal
    id="modal-room-charge" :title="'Cargo habitación: '+nameHotel" size="lg"
    no-close-on-backdrop hide-footer hide-header-close
  >
    <b-card-text>
      <b-container>
        <ValidationObserver ref="observer" tag="form" v-slot="{ invalid }">
          <b-form @submit.prevent="searchDataRoom">
            <b-row>
              <b-col>
                <ValidationProvider rules="required" name="apellido">
                  <b-input-group prepend="Apellido" slot-scope="{ valid, errors }" label="apellido">
                    <b-form-input
                      v-model="cliente.lastnameSearch"
                      :state="errors[0] ? false : (valid ? true : null)"
                      placeholder="Ingrese apellido para buscar huesped"
                      @change="setLastnameCharge(cliente.lastnameSearch)">
                    </b-form-input>
                    <b-input-group-append>
                      <b-button type="submit" :disabled="invalid" :variant=" invalid ? 'danger': 'success'" >Buscar</b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
        <b-form @submit.prevent="applyPayment">
          <b-row class="mt-3">
            <b-col>
              <b-table
                v-if="guests.length"
                :items="guests"
                :fields="tableColumns"
                responsive
                small
                :sort-desc.sync="isSortDirDesc"
                primary-key="id"
                class="table table-sm table-striped"
                thead-class="thead-dark"
                sticky-header="350px"
              >
                <template #cell(mainGuest)="data">
                  <b-row align-v="center" class="width-custom">
                    <b-col md="1" v-if="total > 0 && data.item.iconnopost==0 && availableBalance(data.item) && !!data.item.roomnumber" class="d-inline">
                      <b-form-checkbox
                        v-if="checkoutIsValid(data.item.dateto)"
                        v-model="data.item.selected"
                        :disabled="isAlreadySelected && isAlreadySelected != data.item.accountid"
                        :state="isAlreadySelected && isAlreadySelected == data.item.accountid ? true : null"
                        @change="setNumeroDeReserva(data.item)"
                      />
                    </b-col>
                    <b-col>
                      <b-button
                        :class="`${checkoutIsValid(data.item.dateto) ? 'noDecoration' : 'noDecoration text-red' } p-0 m-0`"
                        @click="checkIfValidDate(data.item.dateto)"
                        variant="link"
                        size="sm"
                        type="button"
                      > {{data.item.guestname}} {{data.item.guestname1}}
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
                <template #cell(guests)="data">
                  <div v-if="data.item.extras && data.item.extras.length">
                    <b-button
                      :id="'popover-guest'+data.index" variant="info" class="btn-sm"
                    > <b-icon-info-circle-fill></b-icon-info-circle-fill>
                    </b-button>
                    <b-popover :target="'popover-guest'+data.index" variant="info" triggers="focus">
                      <template #title>Huespedes en la habitación</template>
                      <b>Principal :</b> {{data.item.guestname}}<br>
                      <div v-for="(extra, i) in data.item.extras" :key="i">
                        <b>{{i + 1 }} :</b> {{extra.guestname}} <br>
                      </div>
                    </b-popover>
                  </div>
                </template>

                <template #cell(creditlimit)="row">
                  <div>
                    <div class="d-flex justify-content-between">
                      <span class="font-weight-bold mr-1">Límite:</span> {{ row.item.ilimitedBalance ? 'Ilimitado' : formatAmount(row.item.creditlimit) }} <br>
                    </div>
                    <!-- <span class="font-weight-bold">Disponible:</span> {{(row.item.creditlimit - row.item.balance)}} <br> -->
                     <div class="d-flex justify-content-between">
                       <span class="font-weight-bold mr-1">Usado:</span> {{ formatAmount(row.item.balance) }}
                     </div>
                  </div>
                </template>

                <template #cell(notes)="row">
                  <div class="w-100">
                    <div v-if="row.item.iconnopost!=0 && total > 0">
                      No tiene permitido cargos habitación <br>
                    </div>
                    <div v-if="total > 0 && !availableBalance(row.item)">
                      Importe a cargar es mayor al credito restante. Credito: {{formatAmount(row.item.creditlimit - row.item.balance)}} <br>
                    </div>
                    <div v-if="!checkoutIsValid(row.item.dateto)">
                      No puede hacer cargos habitación, el Check Out es hoy <br>
                    </div>
                    <div v-if="!row.item.roomnumber">
                      No hay una habitación configurada para este huesped
                    </div>
                  </div>
                </template>
              </b-table>

              <b-alert show variant="danger" v-if="guests.length == 0 && !isLoadingProcess">
                <div class="alert-body text-center"> 😣 <span>No hay información de huéspedes</span></div>
              </b-alert>
              <div class="text-center" v-if="isLoadingProcess">
                <b-spinner label="Loading..." variant="success"/>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col md="6">
              <!-- Totales -->
              <b-card>
                <b-row class="">
                  <b-col cols="12">
                    <div class="client-card d-flex justify-content-between margin-customer-card">
                      <span class="">Subtotal:</span> {{ formatAmount(subTotal) }}
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="client-card d-flex justify-content-between margin-customer-card">
                      <span class="">Descuento:</span> {{ formatAmount(totalDiscount) }}
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="client-card d-flex justify-content-between margin-customer-card border-totals mb-1 font-weight-bolder">
                      <span>Total:</span>USD {{ formatAmount(total) }}
                    </div>
                  </b-col>
                </b-row>
    
                <b-row class="" v-if="cart.cliente.currency.value != 1">
                  <b-col cols="12">
                    <div class="client-card d-flex justify-content-between margin-customer-card" v-if="isReloadingProductsInCart">
                      <b-skeleton width="28%"></b-skeleton><b-skeleton width="24%"></b-skeleton>
                    </div>
                    <div class="client-card d-flex justify-content-between margin-customer-card" v-else>
                      <span class="">Subtotal:</span> {{ formatAmountGlobal(currencySubTotal) }}
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="client-card d-flex justify-content-between margin-customer-card" v-if="isReloadingProductsInCart">
                      <b-skeleton width="28%"></b-skeleton><b-skeleton width="24%"></b-skeleton>
                    </div>
                    <div class="client-card d-flex justify-content-between margin-customer-card" v-else>
                      <span class="">Descuento:</span> {{ formatAmountGlobal(currencyTotalDiscount) }}
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="client-card d-flex justify-content-between margin-customer-card border-totals mb-1 font-weight-bolder pt-half" v-if="isReloadingProductsInCart">
                      <b-skeleton width="28%" class=""></b-skeleton><b-skeleton width="24%"></b-skeleton>
                    </div>
                    <div class="client-card d-flex justify-content-between margin-customer-card border-totals mb-1 font-weight-bolder" v-else>
                      <span>Total:</span>{{currency.code}} {{ formatAmountGlobal(currencyTotal) }}
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col class="text-justify mt-2" v-if="cliente.applyManualRoomCharge">
              <b-alert show variant="warning">
                <div class="alert-body ">Hay por lo menos un producto sin código sihot, por lo cual no se puede realizar el cargo automatico.<br>Favor de realizar el cargo manual directamente en sihot con los siguientes productos: <br>
                  <div v-if="productoSinSihotCode.length">
                    <ul>
                      <li v-for="(p, index) in productoSinSihotCode" :key="index">
                        {{p.nombre}}
                      </li>
                    </ul>
                  </div>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <button class="btn btn-danger mb-2" id="show-modal" @click.prevent="closeModalRoomCharge">
                Cerrar
              </button>
            </b-col>
            <b-col md="6">
                <div class="float-right">
                  <b-form-checkbox v-if="cart.makeRoomCharge"
                    class="mb-5"
                    v-model="cart.cliente.applyManualRoomCharge"
                    name="checkbox-1"
                    value="true"
                    unchecked-value="false"
                    @change="checkCargo($event)"
                    disabled
                  >
                    Cargo Manual
                  </b-form-checkbox>
                  <b-button
                    type="submit"
                    :variant="disabledBtnCharge ? 'danger': 'success'"
                    :disabled="disabledBtnCharge"
                  > <i class="fas fa-save"></i> Cobrar
                  </b-button>
                </div>
              </b-col>
          </b-row>

        </b-form>
      </b-container>
    </b-card-text>
  </b-modal>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { checkItemsCartSihotCode, currentDate, formatDateOnly, globalCurrencyFormat, toCurrency } from '@/helpers/helpers'

export default {
  destroyed(){
    this.eraseModalData()
  },
  data(){
    return {
      selected: null,
      disabledBtnCharge: true,
      isAlreadySelected: null,
      isSortDirDesc: true,
      tableColumns: [
        { key:'mainGuest', class:'w-25 text-center', tdClass: 'p-1', label: 'Nombre'},
        { key:'roomnumber', class:'w-25 text-center text-nowrap', tdClass: 'p-1', label: 'Habitación'},
        { key:'resno', class:'w-25 text-center text-nowrap', tdClass: 'p-1', label: 'Reserva'},
        { key:'dateto', class:'w-25 text-center text-nowrap', tdClass: 'p-1', label: 'Check Out', formatter: value => {
          return formatDateOnly(value, 'es')
        }},
        { key:'creditlimit', class:'w-25 text-center text-nowrap', tdClass: 'p-1', label: 'Crédito'},
        { key:'guests', class:'w-25 text-center', tdClass: 'p-1', label: 'Huespedes'},
        { key:'notes', class:'w-75 text-center', tdClass: 'p-1', label: 'Nota'}
      ],
    }
  },
  props: {
    cliente : {
      type: Object,
      required: true
    },
    nameHotel: {
      type: String,
      required: true,
    }
  },
  computed:{
    ...mapState('auth',['user']),
    ...mapState('shop',['guests', 'cart','isLoadingProcess', 'currency', 'isReloadingProductsInCart']),
    ...mapGetters('shop',['tc', 'subTotalParcial', 'subTotalParcialBooking', 'totalDiscount', 'subTotal', 'total', 'currencySubTotalParcial', 'currencySubTotalParcialBooking', 'currencyTotalDiscount', 'currencyTotal', 'currencySubTotal']),

    productoSinSihotCode(){
      const respuesta = checkItemsCartSihotCode( this.cart.items ) //sihotcode en respuesta
      const { ok } = respuesta
      let items = []
      if( ok ){
        const { objetos } = respuesta
        objetos.forEach(( prod ) => {
          const producto = { nombre: prod.name || '' +' - '+prod.detaildisplay }
          items.push( producto)
        })
      }
      return items
    },

  },
  methods:{
    ...mapActions('shop',['fetchDataRoom','sendInfoClient']),
    ...mapMutations('shop',['setLastNameChargeClient','unsetDataRoom', 'unselectDataRoom','setManualRoomChargeCliente','setReservaRoomCharge','unsetLastNameSearch','unsetMakeRoomCharge']),
    setLastnameCharge( lastname ){
      this.unsetDataRoom()
      this.disabledBtnCharge = true
      this.setLastNameChargeClient( lastname )
    },
    async searchDataRoom(){
      const apellido = this.cliente.lastnameSearch
      const codigoHotel = this.cliente.hotel //hotelsihotcode
      const payload = {hotel: codigoHotel, lastname: apellido, channelId: this.user.canalventa.id }
      this.isAlreadySelected = false
      await this.fetchDataRoom( payload )
      // this.$refs.observer.reset()
    },
    closeModalRoomCharge(){
      this.eraseModalData()
      this.$root.$emit('bv::hide::modal', 'modal-room-charge')
    },
    setNumeroDeReserva(guest){
      const { resno, roomnumber, selected, accountid } = guest
      this.setSelectedSwitch(accountid)
      const payload = {numReserva: resno, room: roomnumber, accountid}
      if( selected ){
        this.disabledBtnCharge = false
        this.setReservaRoomCharge(payload)
      } else {
        this.unselectDataRoom()
        this.unsetMakeRoomCharge()
        this.isAlreadySelected = false
        this.disabledBtnCharge = true
      }
    },
    availableBalance(guest){
      return ((guest.creditlimit - guest.balance) >= this.total) || guest.ilimitedBalance
    },
    async applyPayment(){
      await this.sendInfoClient( this.cliente )
      if( this.cart.items ){
        this.$emit('reset-customer-form')
        this.closeModalRoomCharge()
      }
    },
    checkoutIsValid(date){
      const hoy = currentDate().split('-')
      const checkout = date.split('-')
      if (this.user?.canalventa?.canChargeRoomInCheckout) return true
      else if (checkout[0] == hoy[0] && checkout[1] == hoy[1] && checkout[2] == hoy[2]) return false
      else return true
    },
    checkIfValidDate(date){
      const valid = this.checkoutIsValid(date)
      if (!valid) showAlertMessage(
        "No se pudo hacer cargo a habitación", "BellIcon",
        `No se puede realizar el cargo a esta habitación debido a que hoy tiene su checkout`,
        "warning", 5000, "bottom-right"
      )
      return valid
    },
    setSelectedSwitch(accountid){
      this.isAlreadySelected = accountid
    },
    eraseModalData(){
      this.$emit('reset-room-charge-data')
      this.disabledBtnCharge = true
    },
    formatAmountGlobal(amount){
      return globalCurrencyFormat(amount)
    },
    formatAmount(amount){
      return toCurrency(amount)
    },
  }
}
</script>

<style>
  .noDecoration {
    text-decoration: none;
  }
  .estiloRadioChecked{
    background-color: #bbdefb ;
    border-radius: .3em;
  }
  .textSmall{
    font-size: .95em;
  }
  .text-red{
    color: red;
  }
  .width-custom{
    width: 10rem;
    max-width: 15rem;
  }
</style>